import * as _ from 'lodash';
import PDFObject from 'pdfobject';
import linkifyHtml from 'linkifyjs/lib/linkify-html';
import vNotify from "./notify.service";

/**
 * Controller for displaying User File lists
 */

app.controller('UserApplicationsController', ['$scope', 'Backend', 'Helpers', '$q', 'cfg', '$timeout', '$http',
    '$translate', function ($scope, Backend, Helpers, $q, cfg, $timeout, $http, $translate) {
        // TODO: check if 'documents' is used at all now
        this.original_file_list = {
            applications: [],
            businesses: [],
            documents: [],
            certificates: [],
            messages: [],
            unseenNonAlerts: [],
            alerts: []
        };
        this.file_list = {
            applications: [],
            businesses: [],
            documents: [],
            all_documents: [],
            certified_documents: [],
            other_documents: [],
            certificates: [],
            messages: [],
            unseenNonAlerts: [],
            alerts: []
        };
        this.reverse = true;
        this.form_data = {'A5': {}};
        this.is_draft = false;
        var self = this;
        this.current_messages_page = 1;
        this.current_applications_page = 1;
        this.current_applications_ordering = null;
        this.current_applications_filter = null;
        this.current_user_messages_ordering = '-created_at';
        this.current_all_documents_page = 1;
        this.currentCertificatesPage = 1;
        this.currentDocumentsPage = 1;
        this.page_reload_timeout = 15000;
        this.allLoadedAlerts = [];
        this.GLOBALS = Helpers.GLOBALS;
        this.loaded_services = cfg.services;
        this.currentBE = null;
        this.showAllBEApplications = false;
        this.areApplicationsLoaded = false;
        this.areUnifiedDocumentsLoaded = false;
        this.areDocumentsLoaded = false;
        this.areCertificatesLoaded = false;
        this.areMessagesLoaded = false;
        this.areBELoaded = false;
        this.activeTab = "applications";
        this.items_per_page = "8";
        // noinspection JSUnusedGlobalSymbols
        this.filesPreviewLimit = 3; // Used in parta_dashboard.html
        this.username = '';
        this.unSeenMessagesCount = 0;
        this.totalMessagesCount = 0;
        this.totalFilesCount = 0;
        // noinspection JSUnusedGlobalSymbols
        this.totalUnifiedDocumentsCount = 0; // Used in parta_dashboard.html
        this.totalDocumentsCount = 0;
        this.totalCertificatesCount = 0;
        // noinspection JSUnusedGlobalSymbols
        this.isPreviewModalOpened = false; // Used in parta_dashboard.html
        this.previewModalDocument = {};
        // noinspection JSUnusedGlobalSymbols
        this.previewModalOptions = "full"; // Used in parta_dashboard.html
        this.lastMessageBE = null;
        // noinspection JSUnusedGlobalSymbols
        this.serviceCategories = []; // Used in parta_dashboard.html
        this.allServices = [];
        this.services = [];
        this.currentServiceCategories = localStorage.getItem("currentServiceCategories") ?
            JSON.parse(localStorage.getItem("currentServiceCategories")) : [];
        this.isUserAdmin = false;
        this.filterVisibleServices = false;
        this.filterServicesSearch = null;
        this.limitedView = true;
        // noinspection JSUnusedGlobalSymbols
        this.showLegend = false; // Used in parta_dashboard.html
        this.searchFilter = {service: null, servicePrev: null, company: null, companyPrev: null};
        this.responsiveAppsListSortVisible = false;
        this.responsiveAppsListSearchVisible = false;
        this.appsListSorting = {value: "", reverse: false};
        // noinspection JSUnusedGlobalSymbols
        this.severalMsgsVisible = true; // Used in parta_dashboard.html
        // noinspection JSUnusedGlobalSymbols
        this.myApplicationsHeadingFiltersVisible = false; // Used in parta_dashboard.html
        this.messageSearchPhrase = null;
        this.FileType = {
            CERTIFICATES: 'certificates',
            DOCUMENTS: 'documents'
        };
        this.selectedFileType = this.FileType.CERTIFICATES;
        this.documentsOrderBy = '-created_at';
        this.certificatesOrderBy = '-created_at';

        this.loadBusinessEntities = function () {
            if (GLOBALS.USE_BUSINESS_ENTITIES) {
                return Backend.getBusinessEntities();
            } else {
                var deferred = $q.defer();
                deferred.resolve({results: []});
                return deferred.promise;
            }
        }

        this.initService = function () {
            var opts = Object(arguments[0]);
            self.username = opts.username;
            self.isUserAdmin = opts.isUserAdmin === 'True';
            var promises = [];
            if (self.username) {
                promises.push(self.getUserMessages({page: self.current_messages_page}));
                promises.push(Backend.getTransactions().then(function (data) {
                    self.original_file_list['payments'] = data.data;
                    self.total_payments = data.data.length;
                }));
                promises.push(self.getUserAlerts());
                promises.push(self.getUserFiles());
                promises.push(self.loadBusinessEntities().then(self.processBusinessEntities));
                self.getUserCertificates({page: self.currentCertificatesPage});
                self.getUserDocuments({page: self.currentDocumentsPage});

                $timeout(self.watchFiles, this.page_reload_timeout);
            }
            self.getServices();


            $q.all(promises).then(function () {

                angular.forEach(['documents', 'businesses', 'certificates', 'alerts', 'messages', 'payments'], function (list_type) {
                    self.file_list[list_type] = angular.copy(self.original_file_list[list_type]);
                });

                Helpers.initTippy();
            });
        };

        // noinspection JSUnusedGlobalSymbols
        this.toggleLimitedView = function () {
            // Used in parta_dashboard.html
            this.limitedView = !this.limitedView;
            this.filesPreviewLimit = this.limitedView ? 3 : 10;
        }

        this.sortDocuments = function (list) {
            if (!list) return list;
            list.sort(function (a, b) {
                // this is really about grouping
                return ('' + a.file_id).localeCompare(b.file_id);
            });
            return list.sort(function (a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
            });
        }
        this.sortBusinesses = function (list) {
            if (!list) return list;
            return list.sort(function (a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
            });
        }

        // noinspection JSUnusedGlobalSymbols
        this.sortFiles = function (value) {
            // Used in parta_dashboard.html
            self.appsListSorting.value = value;
            if (value.includes('entity_name')) {
                value = (GLOBALS.USE_BUSINESS_ENTITIES && "business_entity__name") || "meta_data";
            }
            if (self.current_applications_ordering === value || self.current_applications_ordering === ("-" + value)) {
                if (self.current_applications_ordering[0] === "-") {
                    self.current_applications_ordering = self.current_applications_ordering.slice(1);
                    self.appsListSorting.reverse = false;
                } else {
                    self.current_applications_ordering = "-" + self.current_applications_ordering;
                    self.appsListSorting.reverse = true;
                }
            } else {
                self.current_applications_ordering = value;
                self.appsListSorting.reverse = false;
            }
            this.responsiveAppsListSortVisible = false;
            self.getUserFiles();
        }

        this.toggleDocumentsOrCertificatesSortOrder = function () {
            if (self.selectedFileType === self.FileType.CERTIFICATES) {
                if (self.certificatesOrderBy === '-created_at') {
                    self.certificatesOrderBy = 'created_at';
                } else {
                    self.certificatesOrderBy = '-created_at';
                }
                self.getUserCertificates({page: self.currentCertificatesPage});
            } else if (self.selectedFileType === self.FileType.DOCUMENTS) {
                if (self.documentsOrderBy === '-created_at') {
                    self.documentsOrderBy = 'created_at';
                } else {
                    self.documentsOrderBy = '-created_at';
                }
                self.getUserDocuments({page: self.currentDocumentsPage});
            }
        }

        this.getSortOrderClass = function () {
            if (self.selectedFileType === self.FileType.CERTIFICATES) {
                return self.certificatesOrderBy === '-created_at' ? 'reverse' : '';
            } else if (self.selectedFileType === self.FileType.DOCUMENTS) {
                return self.documentsOrderBy === '-created_at' ? 'reverse' : '';
            }
        }

        // noinspection JSUnusedGlobalSymbols
        this.responsiveAppsListSortToggle = function () {
            // Used in parta_dashboard.html
            this.responsiveAppsListSearchVisible = false;
            this.responsiveAppsListSortVisible = !this.responsiveAppsListSortVisible;
        }

        // noinspection JSUnusedGlobalSymbols
        this.responsiveAppsListSearchToggle = function () {
            // Used in parta_dashboard.html
            this.responsiveAppsListSortVisible = false;
            this.responsiveAppsListSearchVisible = !this.responsiveAppsListSearchVisible;
        }

        // noinspection JSUnusedGlobalSymbols
        this.getGroupClass = function (file, group_type) {
            // Used in parta_dashboard.html
            if (file === undefined) {
                return "";
            }

            var mapping_field = null;
            if (file.process_id !== undefined) {
                mapping_field = "process_id";
            } else if (file.file_id !== undefined) {
                mapping_field = "file_id";
            }
            if (mapping_field === null) {
                return "";
            }

            if (!self.temp_group_class) {
                self.temp_group_class = {};
            }
            if (!self.temp_group_class[group_type]) {
                self.temp_group_class[group_type] = [];
            }

            var has_id = false;
            angular.forEach(self.temp_group_class[group_type], function (id_value) {
                if (id_value == file[mapping_field]) {
                    has_id = true;
                }
            });

            if (!has_id) {
                self.temp_group_class[group_type].push(file[mapping_field]);
            }

            var class_name = "";
            angular.forEach(self.temp_group_class[group_type], function (id_value, key) {
                if (id_value == file[mapping_field]) {
                    class_name = !(key % 2) ? 'table-grouped' : '';
                }
            });
            return class_name;
        };

        this.showFileProcessing = function (file) {
            return Helpers.openFileDetail(file);
        };

        // noinspection JSUnusedGlobalSymbols
        this.confirmDeleteFile = function (file, e) {
            // Used in parta_dashboard.html
            e.preventDefault();
            const element = $(e.currentTarget);
            if (element.data()['bs.popover'] && element.data()['bs.popover']['$tip'].hasClass('in')) {
                return;
            }
            document.body.classList.add('delete-confirmation-popover-shown');
            const contentElement = document.createElement('div');
            const messageElement = document.createElement("p");
            messageElement.appendChild(document.createTextNode($translate.instant('Are you sure?')))
            contentElement.appendChild(messageElement);
            const confirmButtonElement = document.createElement('button');
            confirmButtonElement.classList.add('btn', 'btn-sm', 'btn-danger');
            confirmButtonElement.appendChild(document.createTextNode($translate.instant('Confirm')))
            const cancelButtonElement = document.createElement('button');
            cancelButtonElement.classList.add('btn', 'btn-sm', 'btn-secondary');
            cancelButtonElement.appendChild(document.createTextNode($translate.instant('Cancel')))
            const footerContent = document.createElement('div');
            footerContent.setAttribute('class', 'popover-actions');
            footerContent.appendChild(confirmButtonElement);
            footerContent.appendChild(cancelButtonElement);
            contentElement.appendChild(footerContent);
            element.popover({
                customClass: 'delete-confirmation-popover',
                content: contentElement,
                title: $translate.instant('Confirmation'),
                trigger: 'manual',
                placement: 'top',
                html: true,
                sanitize: false
            });
            const bsPopover = element.data()['bs.popover'];
            $(confirmButtonElement).click(() => {
                bsPopover.hide();
                self.deleteFile(file);
                document.body.classList.remove('delete-confirmation-popover-shown');
            })
            $(cancelButtonElement).click(() => {
                bsPopover.hide();
                document.body.classList.remove('delete-confirmation-popover-shown');
            })

            const mouseCallback = (e) => {
                if (element.data()['bs.popover']) {
                    const tip = element.data()['bs.popover']['$tip'];
                    if (!tip.is(e.target) && tip.has(e.target).length === 0 && !element.is(e.target) && element.has(e.target).length === 0) {
                        bsPopover.hide();
                    } else {
                        return;
                    }
                }
                $(document).off('click', mouseCallback);
            }
            element.on('hidden.bs.popover', () => {
                element.css('opacity', "");
                $(document).off('click', mouseCallback);
            });
            element.css('opacity', 1);
            element.popover('show');
            $(document).click(mouseCallback);
        }

        // noinspection JSUnusedGlobalSymbols
        this.rolesPopover = function (file_id, e) {
            // Used in parta_dashboard.html
            e.preventDefault();
            const element = $(e.currentTarget);
            if (element.data()['bs.popover'] && element.data()['bs.popover']['$tip'].hasClass('in')) {
                return;
            }
            let contentElement = $('[content-id="' + file_id + '"]');
            if (contentElement.length > 1) {
                contentElement.each((i, e1) => {
                    if (e1.parentElement && e1.parentElement.parentElement && e1.parentElement.parentElement.classList.contains('popover')) {
                        $(e1.parentElement.parentElement).detach();
                    }
                });
                contentElement = $('[content-id="' + file_id + '"]');
            }
            element.popover({
                customClass: 'roles-statuses-popover',
                content: contentElement,
                trigger: 'hover',
                placement: 'top',
                container: 'body',
                html: true,
                sanitize: false
            });
            const bsPopover = element.data()['bs.popover'];
            const mouseCallback = (e) => {
                if (element.data()['bs.popover']) {
                    const tip = element.data()['bs.popover']['$tip'];
                    if (!tip.is(e.target) && tip.has(e.target).length === 0 && !element.is(e.target) && element.has(e.target).length === 0) {
                        bsPopover.hide();
                    } else {
                        return;
                    }
                }
                $(document).off('click', mouseCallback);
            }
            element.on('hidden.bs.popover', () => {
                element.css('opacity', "");
                $(document).off('click', mouseCallback);
            });
            element.css('opacity', 1);
            element.popover('show');
            $(document).click(mouseCallback);
        }

        this.deleteFile = function (file) {
            Backend.deleteFile(file.file_id).then((response) => {
                if (response.status === 204) {
                    var i = self.original_file_list['applications'].indexOf(file);
                    self.original_file_list['applications'].splice(i, 1);

                    i = self.file_list['applications'].indexOf(file);
                    self.file_list['applications'].splice(i, 1);
                    self.getUserFiles();
                } else if (response.status === 403 && response.data && response.data.message) {
                    vNotify.warning({text: GLOBALS.$translate(response.data.message)});
                }
            });
        };

        this.documentDownload = Helpers.documentDownload;

        this.hideAlert = function (alert, allMsgs = false) {
            if (allMsgs) {
                this.severalMsgsVisible = false;
            } else {
                Backend.hideAlert(alert.id).then(function () {
                    self.file_list.alerts = self.getAlerts(alert);
                    self.getUserMessages({page: self.current_messages_page});
                }).catch(function () {//it's OK to skip it might be 404

                });
            }
        };

        this.markMessageAsRead = function (message) {
            if (message.is_seen) return;
            message.is_seen = true;
            Backend.markMessageAsRead(message.id).then(function () {
                self.recalculateUnseenMessages();
                for (let i = self.file_list.unseenNonAlerts.length - 1; i >= 0; i--) {
                    const each = self.file_list.unseenNonAlerts[i];
                    if (each.id === message.id) {
                        self.file_list.unseenNonAlerts.splice(i, 1);
                        break;
                    }
                }
            }).catch(function () {//it's OK to skip it might be 404
            });
        }

        // noinspection JSUnusedGlobalSymbols
        this.confirmDeleteMessage = function (file) {
            // Used in parta_dashboard.html
            GLOBALS.confirmModal = function (result) {
                if (result) self.deleteMessage(file);
            }
        }

        this.deleteMessage = function (message) {
            if (!message.id) return;
            Backend.deleteMessage(message.id).then(function () {
                self.getUserMessages({page: self.current_messages_page});
            }).catch(function () {//it's OK to skip it might be 404
            });
        }

        // noinspection JSUnusedGlobalSymbols
        this.toggleMessage = function (message) {
            // Used in parta_dashboard.html
            message.isOpened = !message.isOpened;
            if (message.isOpened) {
                self.markMessageAsRead(message);
            }
        }

        // noinspection JSUnusedGlobalSymbols
        this.onMessagesPageChange = function (new_page) {
            // Used in parta_dashboard.html
            self.getUserMessages({page: new_page}).then(function (data) {
                self.current_messages_page = new_page;
            });
        };

        this.getUserFiles = function (/* opts */) {
            var opts = Object(arguments[0]);
            if (!opts.order_by) {
                opts.order_by = self.current_applications_ordering;
            }
            if (!opts.businessEntity && self.currentBE) {
                opts.businessEntity = self.currentBE.business_entity_id;
            }
            if (opts.state) {
                if (self.current_applications_filter !== opts.state) {
                    self.current_applications_filter = opts.state;
                    self.current_applications_page = 1;
                } else {
                    self.current_applications_filter = null;
                    opts.state = null;
                }
            } else if (self.current_applications_filter) {
                opts.state = self.current_applications_filter;
            }
            if (self.searchFilter.service) {
                opts.service = self.searchFilter.service;
                if (self.searchFilter.serviceOld !== self.searchFilter.service) {
                    self.current_applications_page = 1;
                }
            }
            if (self.searchFilter.company) {
                opts.company = self.searchFilter.company;
                if (self.searchFilter.companyOld !== self.searchFilter.company) {
                    self.current_applications_page = 1;
                }
            }

            opts.page = self.current_applications_page;

            return Backend.getUserFiles(opts).then(self.processApplications);
        }

        this.onApplicationsPageChange = function (new_page) {
            self.current_applications_page = new_page;
            self.getUserFiles();
        };
        /**
         * Does preprocess of applications from server and assignes them into proper scope variables
         *
         * @param result
         */
        this.processApplications = function (data) {
            self.areApplicationsLoaded = true;
            self.totalFilesCount = data.count;
            var results = data.results;

            angular.forEach(results, function (file) {
                Helpers.setFileApplicantListingProcessParams(file);
                file.service_icon_url = SERVICE_ICON_URLS[file.service_id];
                if (file.certificates.length) {
                    file.result_title = file.certificates[0].document_name;
                }
            });
            self.file_list['applications'] = self.file_list['all_applications'] = results;
            setTimeout(function () {
                $('[data-toggle="tooltip"]').tooltip();
            }, 300);
            self.setupIsDeletable();
        }

        this.processBusinessEntities = function (data) {
            self.areBELoaded = true;
            self.file_list['businesses'] = self.original_file_list['businesses'] = self.sortBusinesses(data.results);
            if (self.original_file_list['businesses'][0]) {
                if (self.currentBE) {
                    self.original_file_list['businesses'].some(function (be) {
                        if (be.business_entity_id === self.currentBE.business_entity_id) {
                            self.currentBE = be;
                            return true;
                        }
                    });
                }
            }
            self.setupIsDeletable();
        }

        this.getAlerts = function (hidden) {
            if (!self.allLoadedAlerts) return [];
            if (hidden) {
                const indexToRemove = self.allLoadedAlerts.findIndex(alert => alert.id === hidden.id);
                if (indexToRemove !== -1) {
                    self.allLoadedAlerts.splice(indexToRemove, 1);
                }
            }

            return self.allLoadedAlerts;
        };

        this.getUserMessages = _.debounce(function (/* opts */) {
            var opts = Object(arguments[0]);
            self.areMessagesLoaded = false;
            if (self.currentBE && self.currentBE.business_entity_id) {
                if (self.lastMessageBE !== self.currentBE.business_entity_id) { // otherwise the page might not exist
                    opts.page = 1;
                }
                opts.business_entity = self.lastMessageBE = self.currentBE.business_entity_id;
            }
            if (self.messageSearchPhrase && self.messageSearchPhrase.trim()) {
                opts.search_phrase = self.messageSearchPhrase.trim();
            }
            return Backend.getUserMessages(opts).then(function (data) {
                self.areMessagesLoaded = true;
                self.totalMessagesCount = data.count;
                self.file_list.unseenNonAlerts = [];
                data = data.results.map(function (message) {
                    message.isOpened = false;
                    if (message.message_from && GLOBALS.INSTITUTIONS) {
                        var fromInstitutionNames = [];
                        message.message_from.split(",").forEach(function (instId) {
                            GLOBALS.INSTITUTIONS.some(function (institution) {
                                if (String(institution.id) === instId) {
                                    var nameResolved = institution.shortName || institution.name;
                                    if (nameResolved) {
                                        fromInstitutionNames.push(nameResolved);
                                    }
                                    return true;
                                }
                            });
                        });
                        if (fromInstitutionNames.length) {
                            message.message_from = fromInstitutionNames.join(", ");
                        } else {
                            message.message_from = "";
                        }

                    }
                    message.content = linkifyHtml(message.content, {target: '_blank'})
                    if (!message.is_alert && !message.is_seen) {
                        self.file_list['unseenNonAlerts'].push(message);
                    }

                    return message;
                });
                self.original_file_list['messages'] = data;
                self.file_list['messages'] = data;
                self.recalculateUnseenMessages();
            });
        }, 500)

        this.recalculateUnseenMessages = function () {
            var opts = {};
            if (self.currentBE && self.currentBE.business_entity_id) {
                opts.business_entity = self.currentBE.business_entity_id;
            }
            Backend.getUnSeenMessagesCount(opts).then(function (res) {
                self.unSeenMessagesCount = res.data;
            });
        }

        this.getUserAlerts = function () {
            Backend.getUserAlerts().then(function (data) {
                self.allLoadedAlerts = data;
                self.original_file_list['alerts'] = self.getAlerts();
                self.file_list['alerts'] = self.getAlerts();
            });
        }

        this.onCertificatesPageChange = function (newPage) {
            self.currentCertificatesPage = newPage;
            self.getUserCertificates({page: self.currentCertificatesPage});
        }

        this.onDocumentsPageChange = function (newPage) {
            self.currentDocumentsPage = newPage;
            self.getUserDocuments({page: self.currentDocumentsPage});
        }

        this.getUserDocuments = _.debounce(function () {
            var opts = Object(arguments[0]);
            self.areDocumentsLoaded = false;
            if (self.documentSearchPhrase && self.documentSearchPhrase.trim()) {
                opts.search_phrase = self.documentSearchPhrase.trim();
            }
            opts.order_by = self.documentsOrderBy;

            return Backend.getUserDocuments(opts).then(function (data) {
                self.totalDocumentsCount = data.count;
                self.areDocumentsLoaded = true;
                data.results.forEach(function (doc) {
                    doc.file_url = Backend.backend_url + 'files/' + doc.file_id + '/document/' + doc.document_id;
                });
                self.original_file_list['documents'] = data.results;
                self.file_list['documents'] = data.results;
            });
        }, 500)

        this.getUserCertificates = _.debounce(function () {
            var opts = Object(arguments[0]);
            self.areCertificatesLoaded = false;
            if (self.documentSearchPhrase && self.documentSearchPhrase.trim()) {
                opts.search_phrase = self.documentSearchPhrase.trim();
            }
            opts.order_by = self.certificatesOrderBy;

            return Backend.getUserCertificates(opts).then(function (data) {
                self.totalCertificatesCount = data.count;
                self.areCertificatesLoaded = true;
                data.results.forEach(function (doc) {
                    doc.file_url = Backend.backend_url + 'files/' + doc.file_id + '/certificate-user/' + doc.document_id;
                });
                self.original_file_list['certificates'] = data.results;
                self.file_list['certificates'] = data.results;
            });
        }, 500)

        this.getServiceCategories = function () {
            return Backend.getServiceCategories().then(function (res) {
                self.serviceCategories = res;
            });
        }

        this.getServices = function () {
            self.getServiceCategories().then(self.filterServices);
            return Backend.getServices().then(function (res) {
                self.allServices = res;
                self.filterServices();
            });
        }

        this.filterServices = function (visibilityManual = false) {
            var services = [];
            var serviceListFiltering = localStorage.getItem("part-a-visible-services") ? localStorage.getItem("part-a-visible-services") : 0;
            self.filterVisibleServices = !visibilityManual ? serviceListFiltering == 1 ? true : false : self.filterVisibleServices;

            if (!self.currentServiceCategories.length) {
                services = self.allServices.map(function (s) {
                    return s;
                });
            } else {
                services = self.allServices.filter(function (s) {
                    return self.currentServiceCategories.some(function (c) {
                        return c.services.some(function (service) {
                            return service.service_id === s.service_id;
                        });
                    });
                });
            }
            if (self.filterVisibleServices) {
                localStorageSetItemWithFullWarning("part-a-visible-services", 1);
                services = services.filter(function (s) {
                    return s.is_publicly_visible && self.isTileVisible(s.service_id) == 1;
                });
            } else {
                localStorageSetItemWithFullWarning("part-a-visible-services", 0);
            }
            if (self.filterServicesSearch) {
                services = services.filter(function (s) {
                    return $translate.instant(s.name).toLocaleLowerCase()
                        .includes(self.filterServicesSearch.toLocaleLowerCase());
                });
            }
            self.services = services;
        }

        this.watchFiles = function () {
            Backend.requestLoaderVisibility(false);

            self.getUserAlerts();

            self.getUserFiles();

            self.loadBusinessEntities().then(self.processBusinessEntities);

            if (self.activeTab !== 'messages') {
                self.getUserMessages({page: self.current_messages_page});
            }

            Backend.requestLoaderVisibility(true);

            self.page_reload_timeout = self.page_reload_timeout * 2;
            $timeout(self.watchFiles, self.page_reload_timeout);

        };

        $scope.$watch('user_appctrl.currentBE', function (newValue, oldValue) {
            if (self.username) {
                if (newValue) {
                    self.sortBusinesses(newValue.file_set);
                }
                if (self.selectedFileType === self.FileType.CERTIFICATES) {
                    self.getUserCertificates({page: self.currentCertificatesPage});
                } else {
                    self.getUserDocuments({page: self.currentDocumentsPage});
                }
                self.getUserMessages({page: self.current_messages_page});
                self.getUserFiles();
            }
        });

        $scope.$watch('user_appctrl.currentServiceCategories', function (newValue, oldValue) {
            self.filterServices();
        });

        $scope.$watch('user_appctrl.filterVisibleServices', function () {
            self.filterServices(true);
        });

        $scope.$watch('user_appctrl.filterServicesSearch', function () {
            self.filterServices();
        });

        $scope.$watch('user_appctrl.messageSearchPhrase', function () {
            // reset page when using search
            if (self.username) {
                self.getUserMessages({page: 1});
            }
        });

        $scope.$watch('user_appctrl.documentSearchPhrase', function () {
            // reset page when using search
            if (self.username) {
                if (self.selectedFileType === self.FileType.CERTIFICATES) {
                    self.getUserCertificates({page: 1});
                } else {
                    self.getUserDocuments({page: 1});
                }
            }
        });

        this.toggleServiceCategory = function (category) {
            var index = -1;
            if (!category) {
                self.currentServiceCategories = [];
                localStorage.removeItem("currentServiceCategories");
                return;
            }
            self.currentServiceCategories.some(function (cat, i) {
                if (cat.category_id === category.category_id) {
                    index = i;
                    return true;
                }
            });
            if (index === -1) {
                self.currentServiceCategories.push(category);
            } else {
                self.currentServiceCategories.splice(index, 1);
            }
            self.currentServiceCategories = self.currentServiceCategories.map(function (c) {
                return c;
            });
            if (self.currentServiceCategories && self.currentServiceCategories.length) {
                localStorageSetItemWithFullWarning("currentServiceCategories", JSON.stringify(self.currentServiceCategories));
            } else {
                localStorage.removeItem("currentServiceCategories");
            }
        }

        this.isCategoryActive = function (cat) {
            return self.currentServiceCategories.some(function (category) {
                return category.category_id === cat.category_id;
            });
        }

        this.setupIsDeletable = function () {
            if (!self.file_list['applications']) return;
            self.file_list['applications'].forEach(function (f) {
                if (f.user.username === self.username) {
                    f.isDeletable = true;
                    return;
                }
                if (self.original_file_list['businesses'].length) {
                    self.original_file_list['businesses'].some(function (be) {
                        return be.file_set.some(function (file) {
                            if (file.file_id === f.file_id) {
                                f.isDeletable = be.has_create_access;
                                return true;
                            }
                        });
                    })
                }
            });
        }

        this.docPreviewModal = function (document, fileResult = false, certificateIndex = 0) {
            self.isPreviewModalOpened = true;
            self.previewModalOptions = fileResult ? null : 'full';
            self.previewModalDocument.isResult = fileResult;
            if (document.certificates && document.certificates.length) {
                self.previewModalDocument.resultsLength = document.certificates.length;
            }

            if (fileResult) {
                if (document.certificates.length) {
                    self.previewModalDocument.currentDocument = document.certificates[certificateIndex];
                    self.previewModalDocument.documentList = document.certificates;
                    self.previewModalDocument.serviceName = document.service_name;
                    self.previewModalDocument.currentResult = certificateIndex;
                    self.previewModalDocument.file_url_base = Backend.backend_url + 'files/' + document.file_id + '/certificate-user/';
                    // Below is needed for Download button to work
                    self.previewModalDocument.currentDocument.file_url = self.previewModalDocument.file_url_base + self.previewModalDocument.currentDocument.document_id;
                }
            } else {
                self.previewModalDocument.currentDocument = document;
            }

            PDFObject.embed(self.previewModalDocument.currentDocument.file_url,
                angular.element('#doc-preview-container')[0]);
        }

        this.certificateUrl = function (fileObj, index = 0) {
            if (fileObj.certificates.length) {
                return Backend.backend_url + 'files/' + fileObj.file_id + '/certificate-user/' + fileObj.certificates[index].document_id;
            }
        }

        this.resultPage = function (page) {
            var targetPage = self.previewModalDocument.currentResult + parseInt(page);
            if (targetPage >= 0 && targetPage <= self.previewModalDocument.resultsLength - 1) {
                self.previewModalDocument.currentResult = targetPage;
                self.previewModalDocument.currentDocument = self.previewModalDocument.documentList[targetPage];
                self.previewModalDocument.currentDocument.file_url = self.previewModalDocument.file_url_base +
                    self.previewModalDocument.currentDocument.document_id;
                var newIframeUrl = self.previewModalDocument.file_url_base + self.previewModalDocument.documentList[targetPage].document_id;
                PDFObject.embed(newIframeUrl, angular.element('#doc-preview-container')[0]);
            }
        }

        this.isDocumentExpired = function (expirationDate) {
            if (!expirationDate) return false;
            return new Date() > new Date(expirationDate);
        }

        this.expiringSoon = function (expirationDate) {
            if (!expirationDate) return false;
            var datesDiff = Math.round((new Date(expirationDate) - new Date()) / (1000 * 60 * 60 * 24));
            return datesDiff > 0 && datesDiff < 30;
        }

        this.closePreviewModal = function (event) {
            event.originalEvent.stopPropagation();
            self.isPreviewModalOpened = false;
        }

        this.getUsersBySearch = _.debounce(function () {
            if (self.username) {
                self.getUserFiles();
            }
            self.searchFilter.serviceOld = self.searchFilter.service;
            self.searchFilter.companyOld = self.searchFilter.company;
        }, 500)

        $scope.$watch('user_appctrl.searchFilter', function (newValue, oldValue) {
            self.getUsersBySearch();
        }, true);

        this.isTileVisible = function (service_id) {
            if (!(localStorage.getItem("serviceVisibility-" + service_id))) return true;
            return localStorage.getItem("serviceVisibility-" + service_id);
        }

        // noinspection JSUnusedGlobalSymbols
        this.toggleFileType = () => {
            // Used in parta_dashboard.html
            self.selectedFileType = self.selectedFileType === self.FileType.CERTIFICATES ? self.FileType.DOCUMENTS : self.FileType.CERTIFICATES;
            if (self.selectedFileType === self.FileType.CERTIFICATES) {
                self.getUserCertificates({page: 1});
            } else {
                self.getUserDocuments({page: 1});
            }
        };
    }]);